import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

const Education = ({ data, location  }) => (
  <Layout>
    <SEO
      pagetitle="micro:bit v2.2基本キットのmicro:bit basic（マイクロビット ベーシック）です。iftinyが提供いたします。"
      pagedesc="microbit v2.2でプログラミングを楽しく！ たくさんのご要望を集約し、改善を行ったより使いやすいセットを提供いたします。"
      pagepath={location.pathname}
    />

    <section className="photo">
      <h2 className="sr-only">Photo</h2>
      <figure>
        <Img
          fluid={data.edu_product.childImageSharp.fluid}
          alt="micro bit（マイクロビット）を中心にプログラミング教育教材を提供いたします。"
          style={{ height: "100%" }}
        />
      </figure>
    </section>

    <section className="feature">
      <div className="container">
        <h2 className="bar">
          iftiny <span>micro:bit basic</span>
        </h2>
        <br />
        <br />
        <div className="intro">
          <p>
            micro:bit（マイクロビット）v2.2を動作させる上で、必要なmicro:bit v2.2本体、
            電池ボックス、シリコンケース、USBケーブル、それらを収納する専用バッグをセットで提供いたします。<br />
            各付属品を使って、よりmicro:bitの楽しさを体験できます。
            また、ポケットに入るサイズなので、自分でプログラミングした作品を気軽に持ち歩くことができます。
          </p>
            
          <figure>
            <Img fluid={data.edu_product_microbit_basic_pct01.childImageSharp.fluid} alt="" />
          </figure>
        </div>

        <div class="box26">
          <span class="box-title">ご購入について</span>
          <br />
          <div class="box25">
            <br />
            <p>
                当社ウェブストアとAmazonにて販売をしております。
                <br />
                <br />
                自治体、学校、法人のお客様によるお見積依頼は、<a href="https://store.iftiny.com/pages/request-for-quotation" rel="noreferrer" target="_blank" onClick="ga('send', 'event', 'micro:bit_basic', 'amazon', 'consumer');"><span style={{ color: '#32b16c'}}>お見積り依頼フォーム</span></a>もしくは担当営業までお問合せください。<br />
            </p>
            <br />
          </div>


          <center>
            <div className="details">
              <div className="detailImg">
                <div className="btn-wrap">
                  <a href="https://store.iftiny.com/products/iftiny-microbit-basic?variant=39935386845381" rel="noreferrer" target="_blank" className="btn btn-c" onClick="ga('send', 'event', 'micro:bit_basic', '/jp/contact', 'corp');"><span>販売中</span><br />iftiny store</a>
                </div>
              </div>
              <div className="detailImg">
                <div className="btn-wrap">
                  <a href="https://www.amazon.co.jp/gp/product/B09364FXR3?language=ja_JP" rel="noreferrer" target="_blank" className="btn btn-c" onClick="ga('send', 'event', 'micro:bit_basic', 'amazon', 'consumer');"><span>販売中</span><br />Amazon</a>
{/*                   <a href="https://www.amazon.co.jp/s?k=%E3%83%9E%E3%82%A4%E3%82%AF%E3%83%AD%E3%83%93%E3%83%83%E3%83%88&__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&ref=nb_sb_noss_1&field-brand=iftiny" rel="noreferrer" target="_blank" className="btn btn-c" onClick="ga('send', 'event', 'micro:bit_basic', 'amazon', 'consumer');"><span>個人のお客様</span><br />Amazon</a>
 */}                </div>
              </div>
            </div>
          </center>
          
        </div>
        <br />
        <br />
        <br />


        <div class="box26">
          <span class="box-title">概要</span>
          <br />
          <div class="box25">
            <br />
            <p>
                たくさんのご要望を集約し、改善を行ったこだわりのセットです。
                <br />
                <br />
                初めてのプログラミング学習にも対応します。
            </p>
            <br />
          </div>
          <br />
          <br />
          <div className="details">
            <div className="detailImg">
                <figure>
                  <Img fluid={data.edu_product_microbit_basic_pct12.childImageSharp.fluid} alt="" />
                </figure>
            </div>
            <div className="detailText">
                <h3>迷わずにプログラミングを始められる</h3>
                <p>
                  初めてプログラミングにチャレンジしたい方にとって、もっともハードルが高いのは、必要な機材の準備、機器のセットアップと一回目のプログラミングです。<br />
                  「micro:bit basic」を利用することで、最低限必要なモノを揃えることができ、何を購入すべきかを悩むことなく、プログラミング学習を始めることができます。<br />
                  また、本セットでは、詳細手順を記載したオンラインドキュメントを無料で提供し、パソコン、タブレット、スマートフォンがお手元にあれば、すぐに始めることができます。<br />
                </p>
            </div>
          </div>

          <div className="details">
            <div className="detailImg">
                <figure>
                  <Img fluid={data.edu_product_microbit_basic_pct13.childImageSharp.fluid} alt="" />
                </figure>
            </div>
            <div className="detailText">
                <h3>付属品一覧</h3>
                <p>
                  micro:bit v2.2 × 1<br />
                  micro:bit v2.2用シリコンケース × 1<br />
                  電池ボックス × 1<br />
                  USBケーブル × 1<br />
                  専用バッグ × 1<br />
                  オンラインドキュメント<br />
                </p>
            </div>
          </div>
        </div>

        <br />

        <div class="box26">
          <span class="box-title">付属品-1　micro:bit v2.2本体</span>
          <br />
          <div class="box25">
            <br />
            <p>
                プログラミング学習でmicro:bit（マイクロビット）を用いることに様々なメリットがあリます。
                <br />
                <br />
                本セットでは、micro:bit v2.2（1つ）が含まれています。※色はランダムです。
            </p>
            <br />
          </div>
          <br />
          <br />
          <div className="details">
            <div className="detailImg">
                <figure>
                  <Img fluid={data.edu_product_microbit_basic_pct02.childImageSharp.fluid} alt="" />
                </figure>
            </div>
            <div className="detailText">
                <h3>シンプル・多機能</h3>
                <p>
                  micro:bit（マイクロビット）v2.2は、小さいなボディに多種な機能をまとめ、操作がシンプルで、プログラミング教育に最適なデバイスです。<br />
                  図形や文字を表示できるLED、左右二つのボタン、マイク、スピーカー、加速度センサー、磁気センセー、温度センサー、タッチセンサー、
                  光センサー、簡易でありながら、よく使う機能が揃っています。
                  また、優れたコストパフォーマンスを持ちながら、完成度の高い無料ソフトウェア（Microsoft社 MakeCode）を使い、豊富な関連製品を利用可能なため、
                  レベルに応じて一貫としたプログラミング教育を行うことができます。<br />
                  そのほか、世界で活用されているため、活用事例も多く、調査やトラブル解決も比較的に行いやすいです。<br /><br />
                  micro:bitを採用するメリットについて、詳しくは当社ドキュメント
                  <Link to={`https://docs.iftiny.com/jp/education/microbit/stand-alone/info/why-microbit/`}>
                    <div className="textLink">
                      「なぜmicro:bitがおすすめか？」
                    </div>
                  </Link>
                  をご覧ください。
                </p>
            </div>
          </div>
        </div>

        <br />

        <div class="box26">
          <span class="box-title">付属品-2　micro:bit v2専用シリコンケース</span>
          <br />
          <div class="box25">
            <br />
            <p>
                micro:bit（マイクロビット）v2.2を安全に動作させるために、専用ケースの使用を推奨します。
                <br />
                <br />
                本セットでは、micro:bit v2.2専用シリコンケース（1つ）が含まれています。
            </p>
            <br />
          </div>
          <br />
          <br />
          <div className="details">
            <div className="detailImg">
                <figure>
                  <Img fluid={data.edu_product_microbit_basic_pct03.childImageSharp.fluid} alt="" />
                </figure>
            </div>
            <div className="detailText">
                <h3>安全にmicro:bitを扱える</h3>
                <p>
                  micro:bit（マイクロビット）は静電気や水分に弱いため、素手での扱いは故障の原因になることがあります。
                  専用ケースをつけることで、より安心に扱うことができます。
                </p>
            </div>
          </div>

          <div className="details">
            <div className="detailImg">
                <figure>
                  <Img fluid={data.edu_product_microbit_basic_pct04.childImageSharp.fluid} alt="" />
                </figure>
            </div>
            <div className="detailText">
                <h3>フィットしてカラフル</h3>
                <p>
                  micro:bit（マイクロビット）v2専用ケースは、ボタン、LEDディスプレイ、マイク、タッチセンサー、スピーカーに影響を及ぼさない設計です。
                  カラフルなシリコン製で伸縮性があるため、フィットして脱着がしやすく、子どもにも扱いやすいです。
                  v2.0とv2.2両方での利用が可能です。
                </p>
            </div>
          </div>
        </div>

        <br />

        <div class="box26">
          <span class="box-title">付属品-3　電池ボックス</span>
          <br />
          <div class="box25">
            <br />
            <p>
                micro:bit（マイクロビット）単体への電源供給は、専用電池ボックスの利用を推奨します。
                <br />
                <br />
                本セットでは、専用電池ボックス（1つ）が含まれています。
            </p>
            <br />
          </div>
          <br />
          <br />
          <div className="details">
            <div className="detailImg">
                <figure>
                  <Img fluid={data.edu_product_microbit_basic_pct05.childImageSharp.fluid} alt="" />
                </figure>
            </div>
            <div className="detailText">
                <h3>カラフルで電源スイッチ付き</h3>
                <p>
                  電池ボックスは、micro:bit（マイクロビット）推奨の単四型乾電池タイプです。
                  スイッチ付きで、電源をより簡単にON/OFFできます。<br />
                  ※ 本セットは乾電池を付属していません。
                </p>
            </div>
          </div>
  
          <div className="details">
            <div className="detailImg">
                <figure>
                  <Img fluid={data.edu_product_microbit_basic_pct06.childImageSharp.fluid} alt="" />
                </figure>
            </div>
            <div className="detailText">
                <h3>優れたコネクター・ケーブル</h3>
                <p>
                従来、電源コネクターを外しにくいことがあったが、
                micro:bit（マイクロビット）側のコネクターと同じメーカー製品を利用し、
                取り外しの改良を重ねた。
                また、ケーブルも頑丈なタイプに改良し、より故障しにくいです。
                </p>
            </div>
          </div>

        </div>

        

        <br />

        <div class="box26">
          <span class="box-title">付属品-4　USBケーブル</span>
          <br />
          <div class="box25">
            <br />
            <p>
                micro:bit（マイクロビット）とPCの接続で必要なUSBケーブルを提供いたします。
                <br />
                <br />
                本セットでは、専用USBケーブル（1つ）が含まれています。
            </p>
            <br />
          </div>
          <br />
          <br />
          <div className="details">
            <div className="detailImg">
                <figure>
                  <Img fluid={data.edu_product_microbit_basic_pct07.childImageSharp.fluid} alt="" />
                </figure>
            </div>
            <div className="detailText">
                <h3>最適な長さで収納しやすい</h3>
                <p>
                  子どもの取り扱いを想定して、様々な部材を比較し、厳選して作りました。
                  より携帯しやすく、扱いやすいように、通常のUSBケーブルより短くしています。
                  また、専用バッグに収納しやすいよう、ケーブルを束ねるパーツも付属しています。
                  （PC側：USB Type-A、micro:bit側：Micro USB Type-B）
                </p>
            </div>
          </div>
        </div>

        <br />

        <div class="box26">
          <span class="box-title">付属品-5　専用収納バッグ</span>
          <br />
          <div class="box25">
            <br />
            <p>
                セット全体がピッタリ収納できる専用バッグをご用意しました。
                <br />
                <br />
                本セットでは、専用収納バッグ（1つ）が含まれています。
            </p>
            <br />
          </div>
          <br />
          <br />
          <div className="details">
            <div className="detailImg">
                <figure>
                  <Img fluid={data.edu_product_microbit_basic_pct08.childImageSharp.fluid} alt="" />
                </figure>
            </div>
            <div className="detailText">
                <h3>携帯しやすい</h3>
                <p>
                  コンパクトなサイズで、いつでも自慢の作品を持って出かけることができます。
                  お友達と一緒に製作する時、学校から持ち帰る時など、より携帯しやすくなります。
                  ストラップやカラビナ取り付け用の金具があり、ネームタグを付けるのにも便利です。
                  また、簡易的な防水機能付きなので、安心して持ち運ぶことができます。
                </p>
            </div>
          </div>
          <div className="details">
            <div className="detailImg">
                <figure>
                  <Img fluid={data.edu_product_microbit_basic_pct09.childImageSharp.fluid} alt="" />
                </figure>
            </div>
            <div className="detailText">
                <h3>最適な大きさと保護</h3>
                <p>
                  micro:bit（マイクロビット）、シリコンケース、電池ボックス、USBケーブルを衝撃等から保護し、
                  ピッタリ収納できるサイズです。
                  機器故障を防ぐだけでなく、かばん等の整理もしやすくなります。
                  また、摩擦に強い素材を使用しており、より長くお使いいただけます。
                </p>
            </div>
          </div>
        </div>

        <br />

        <div class="box26">
          <span class="box-title">付属品-6　無料ドキュメント</span>
          <br />
          <div class="box25">
            <br />
            <p>
                micro:bit（マイクロビット）を扱うために必要な基本情報をまとめたドキュメントを提供いたします。
                <br />
                <br />
                オンラインドキュメントなので、いつでもどこでもご利用できます。
            </p>
            <br />
          </div>
          <br />
          <br />
          <div className="details">
            <div className="detailImg">
                <figure>
                  <Img fluid={data.edu_product_microbit_basic_pct10.childImageSharp.fluid} alt="" />
                </figure>
            </div>
            <div className="detailText">
                <h3>初心者でも利用しやすい</h3>
                <p>
                  micro:bit（マイクロビット）v2.2の概要、仕様、利用方法・手順、基本機能、及びプログラムサンプルなどを提供いたします。
                  初めてmicro:bitを扱う初心者の方がより理解しやすいよう、本体以外に、PC、ソフトウェアなど必要なモノの扱い方も記載をしています。
                  中級者以上の方むけに、データシートなどを記載しており、リファレンスとしても利用することが可能です。
                </p>
            </div>
          </div>

          <div className="details">
            <div className="detailImg">
                <figure>
                  <Img fluid={data.edu_product_microbit_basic_pct11.childImageSharp.fluid} alt="" />
                </figure>
            </div>
            <div className="detailText">
                <h3>簡単なコードのダウンロード</h3>
                <p>
                  ドキュメントのウェブサイトにて、動作イメージとビジュアルプログラミングコードの確認だけでなく、
                  直接プログラムのサンプルをmicro:bitにダウンロードすることもできます。すぐにサンプルを試したい方はぜひお使いください。<br /><br />
                  詳しくは
                  <Link to={`https://docs.iftiny.com/jp/education/microbit/`}>
                    <div className="textLink">
                      当社micro:bit v2.2関連ドキュメント
                    </div>
                  </Link>
                  をご覧ください。
                </p>
            </div>
          </div>
        </div>

        <br />
        <div class="box26">
          <span class="box-title">ご購入について</span>
          <br />
          <div class="box25">
            <br />
            <p>
                当社ウェブストアとAmazonにて販売をしております。
                <br />
                <br />
                自治体、学校、法人のお客様によるお見積依頼は、<a href="https://store.iftiny.com/pages/request-for-quotation" rel="noreferrer" target="_blank" onClick="ga('send', 'event', 'micro:bit_basic', 'amazon', 'consumer');"><span style={{ color: '#32b16c'}}>お見積り依頼フォーム</span></a>もしくは担当営業までお問合せください。<br />
            </p>
            <br />
          </div>

          <center>
            <div className="details">
              <div className="detailImg">
                <div className="btn-wrap">
                  <a href="https://store.iftiny.com/products/iftiny-microbit-basic?variant=39935386845381" rel="noreferrer" target="_blank" className="btn btn-c" onClick="ga('send', 'event', 'micro:bit_basic', '/jp/contact', 'corp');"><span>販売中</span><br />iftiny store</a>
                </div>
              </div>
              <div className="detailImg">
                <div className="btn-wrap">
                  <a href="https://www.amazon.co.jp/gp/product/B09364FXR3?language=ja_JP" rel="noreferrer" target="_blank" className="btn btn-c" onClick="ga('send', 'event', 'micro:bit_basic', 'amazon', 'consumer');"><span>販売中</span><br />Amazon</a>
{/*                   <a href="https://www.amazon.co.jp/s?k=%E3%83%9E%E3%82%A4%E3%82%AF%E3%83%AD%E3%83%93%E3%83%83%E3%83%88&__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&ref=nb_sb_noss_1&field-brand=iftiny" rel="noreferrer" target="_blank" className="btn btn-c" onClick="ga('send', 'event', 'micro:bit_basic', 'amazon', 'consumer');"><span>個人のお客様</span><br />Amazon</a>
 */}                </div>
              </div>
            </div>
          </center>
          
        </div>
        <p>
          ※デザインおよび色合いは予告なく変更することがあります。
        </p>
        <br />
        <br />
        <br />
        <br />


      </div>
    </section>
  </Layout>
)

export const query = graphql`
  query {
    edu_product_microbit_basic_pct01: file(relativePath: { eq: "edu_product_microbit_basic_pct01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    edu_product_microbit_basic_pct02: file(relativePath: { eq: "edu_product_microbit_basic_pct02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    edu_product_microbit_basic_pct03: file(relativePath: { eq: "edu_product_microbit_basic_pct03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    edu_product_microbit_basic_pct04: file(relativePath: { eq: "edu_product_microbit_basic_pct04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    edu_product_microbit_basic_pct05: file(relativePath: { eq: "edu_product_microbit_basic_pct05.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    edu_product_microbit_basic_pct06: file(relativePath: { eq: "edu_product_microbit_basic_pct06.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    edu_product_microbit_basic_pct07: file(relativePath: { eq: "edu_product_microbit_basic_pct07.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    edu_product_microbit_basic_pct08: file(relativePath: { eq: "edu_product_microbit_basic_pct08.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    edu_product_microbit_basic_pct09: file(relativePath: { eq: "edu_product_microbit_basic_pct09.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    edu_product_microbit_basic_pct10: file(relativePath: { eq: "edu_product_microbit_basic_pct10.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    edu_product_microbit_basic_pct11: file(relativePath: { eq: "edu_product_microbit_basic_pct11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    edu_product_microbit_basic_pct12: file(relativePath: { eq: "edu_product_microbit_basic_pct12.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    edu_product_microbit_basic_pct13: file(relativePath: { eq: "edu_product_microbit_basic_pct13.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    edu_product: file(relativePath: { eq: "edu_product.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    children: file(relativePath: { eq: "children.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pattern: file(relativePath: { eq: "pattern.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allContentfulBlogPost(
      sort: { order: DESC, fields: publishDate }
      filter: {category: { elemMatch: { categorySlug: { eq : "solutioninfo" }}}}
      skip: 0
      limit: 4
    ) {
      edges {
        node {
          title
          id
          slug
          eyecatch {
            fluid(maxWidth: 573) {
              ...GatsbyContentfulFluid_withWebp
            }
            description
          }
        }
      }
    }
  }
`

export default Education
